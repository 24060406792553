<template>
    <header id="header">
        <div class="container-navbar">
            <div class="container">
                <div class="row">
                    <div class="nav-block d-flex justify-content-between align-items-center">
                        <div class="logo-navbar d-flex justify-content-between align-items-center">
                            <div class="logo-navbar d-flex justify-content-between align-items-center">
                                <div class="dropdown">
                                    <button class="btn-vector" type="button" id="dropdownMenuButton"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../assets/images/dots-menu.png" alt="Vector" class="Vector">
                                    </button>

                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <a href="https://edo.faktura.uz/" target="_blank" class="InVector-item d-block">
                                                        <img src=".././assets/images/edo.png" alt="png"
                                                            class="vector-in-img" style="height: 38px; width: auto">
                                                        <p class="InVector-description">Сервис для обмена электронными
                                                            документами</p>
                                                </a>
                                            </div>
                                            <div class="col-lg-6">
                                                <a href="https://report.faktura.uz/" target="_blank" class="InVector-item d-block">
                                                        <img src=".././assets/images/report-logo.png" alt=""
                                                            class="vector-in-img" style="height: 38px; width: auto">
                                                        <p class="InVector-description">Генерация готовых отчетов за
                                                            указанный период</p>
                                                </a>
                                            </div>

                                            <div class="col-lg-6">
                                                <a href="https://botcrm.faktura.uz/" target="_blank" class="InVector-item d-block">
                                                        <img src=".././assets/images/crm.png" alt="png"
                                                            class="vector-in-img" style="height: 30px; width: auto">
                                                        <p class="InVector-description mt-1">Конструктор чат-ботов для
                                                            Telegram</p>
                                                </a>
                                            </div>
                                            <div class="col-lg-6">
                                                <a href="https://push.faktura.uz/" target="_blank" class="InVector-item d-block">
                                                        <img src="../assets/images/push.png" alt="png"
                                                            class="vector-in-img" style="height: 32px; width: auto">
                                                        <p class="InVector-description">Рассылка рекламных материалов по
                                                            базе ИНН</p>
                                                </a>
                                            </div>
                                            <div class="col-lg-6">
                                                <a href="https://org.faktura.uz/" target="_blank" class="InVector-item d-block">
                                                        <img src="../assets/images/ORG.png" alt="png"
                                                            class="vector-in-img" style="height: 30px; width: auto">
                                                        <p class="InVector-description">Сервис для проверки контрагентов</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class="col-lg-12 mt-4 mb-4">

                                                <a class="home-redirect-btn" target="_blank" href="https://faktura.uz/">
                                                    Все продукты от Faktura.uz
                                                    <svg width="11" height="8" viewBox="0 0 11 8"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464466C6.97631 0.269204 6.65973 0.269204 6.46447 0.464466C6.2692 0.659728 6.2692 0.976311 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM0 4.5L10 4.5V3.5L0 3.5L0 4.5Z" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </ul>
                                </div>
                                <router-link :to="{ name: 'home' }"><img src="../assets/images/logo.svg" alt="logo"
                                        style="width:210px; height:auto;"></router-link>
                            </div>
                        </div>
                        <div class="navbar-box d-flex align-items-center justify-content-between">
                            <ul class="box-menu me-3">
                                    <a href="/#service">
                                        <li class="nav-item">О сервисе</li>
                                    </a>
                                    <a href="/#possibilities">
                                        <li class="nav-item "> Возможности</li>
                                    </a>
                                    <a href="/#price">
                                        <li class="nav-item ">Цена</li>
                                    </a>
                                </ul>
                            <div class="d-flex align-items-center position-relative"
                                style="background-color: #E7EEFF; border-radius: 10px; border: none;">
                                <div id="UserName" class="d-flex align-items-center  ms-lg-2"
                                    style="cursor: pointer; user-select: none" v-if="getAuth() && !isLoading"
                                    @click="showUserLimit = !showUserLimit">
                                    <div  class="me-lg-2" >
                                        <img id="UserName" src="../assets/images/user.png" width="30" alt="">
                                    </div>
                                    <div   class="d-none my-1 me-1 d-lg-block">
                                       <div id="UserName" style="font-size: 15px;"> {{ visitor?.name }} </div>
                                       <div id="UserName" class="text-green text-nowrap">{{ visitor?.balance }} Сум</div>
                                    </div>
                                    <div v-if="showUserLimit" class="card position-absolute p-3"
                                        style="top: 50px; right: 10px; width: 300px; z-index:11; user-select: none">
                                        <div class="d-lg-none">
                                            <h6>{{ getUserName() }} </h6>
                                        </div>
                                        <h5 class="mb-1 fs-5">Тариф: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Лимит 10</h5>
                                        <div>
                                            <button @click="logOut()" id="SignButton" class="btn-sign-in ms-0 py-2 d-lg-none"> Выйти</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="isLoading">
                                    <div style="width: 24px;" class="loader ms-2"></div>
                                </div>

                                <button v-if="!getAuth()" @click="getTickectFromFaktura()" id="SignButton"
                                    class="btn-sign-in ms-0">Войти</button>
                                <button v-else @click="logOut()" id="SignButton" class="btn-sign-in ms-0 d-none d-lg-block"> Выйти</button>
                            </div>
                        </div>
                    </div>

                    <transition name="slide-fade">
                        <div v-show="false" v-cloak class="navbar-collapse">
                            <!-- <ul class="navbarMobile">
                                    <li class="nav-item ">Помощь</li>
                                    <div class="Line-crm"></div>
                                    <li class="nav-item ">Заявления</li>
                                    <div class="Line-crm"></div>
                                    <li class="nav-item ">Документы</li>
                                    <div class="Line-crm"></div>
                                    <li class="nav-item ">Услуги</li>
                                    <div class="Line-crm"></div>
                                </ul> -->
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import AuthService from '@/services/authService';
import { mapActions, mapGetters } from 'vuex';
export default {
    data() {
        return {
            showUserLimit: false,
            Gamburger: false,
            isLoading: false
        }
    },
    computed: {
        visitor() {
            return this.getVisitor();
        }
    },
    methods: {
        ...mapActions([
            'visitorInfo_api'
        ]),
        getTickectFromFaktura() {
            let qs = `response_type=code&client_id=FakturaORGClientId&redirect_uri=` + window.location;
            window.location.href = `https://account.faktura.uz/Authorize?${qs}`;
        },
        logOut() {
            this.showUserLimit = false;
            AuthService.logOut();
            window.location.reload();
        },
        ...mapGetters([
            'getAuth',
            'getUserName',
            'getVisitor'
        ])
    }
    ,
    watch: {
        'getAuth'(to, from) {
            if (to == false) {
                this.showUserLimit = false;
            }
        }
    },
    async mounted() {
        if (AuthService.Authorization()){
            this.isLoading = true;
            await this.visitorInfo_api();
            this.isLoading = false;
        }
        let vm = this;
        window.addEventListener('click', function (event) {
            if (event.target.id != 'UserName'){
                vm.showUserLimit = false
            }
        })
    }

}


</script>