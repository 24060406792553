<template >
    <div id="SearchForLandingPageComponent" >

    <form @submit="submitSearch(this.searchText)" class="input-group">
        <input id="InputSuggestion" type="text" v-model="searchText"
            placeholder="Название    /    ИНН    /    Генеральный директор" class="form-control" key
            aria-describedby="basic-addo" autocomplete="off" @input="this.debounce()" >
            <div class="actions-for-suggestion position-absolute align-items-center d-flex align-middle" >
                <div v-if="isProcess" class="d-flex align-items-center" style="margin-right: 10px;">
                    <div class="spinner-border text-primary " style="width: 25px; height: 25px;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <button v-else-if="searchText?.length > 1" @click="clearSearch()"  class="btn text-danger d-flex align-items-center">
                        <i class="bi bi-x-lg" style="font-size: 18px;"></i></button>
                <button class="btn search-btn" type="submit">НАЙТИ</button>
            </div>
        <div v-if="showModal && ( searchSuggestion.companies || searchSuggestion.founders) && searchText" class="card p-2 card-for-company-names">
            <div class="card rounded p-0" v-if="searchSuggestion.companies" style="background-color: rgb(232, 240, 254);">
                <div class="card-header d-flex text-dark text-start">
                    <img src="../assets/images/company.png" class="me-2" width="24px" alt="companies">
                    <h5 class="m-0">
                        Организации
                    </h5>
                </div>
                <div class="card-body px-3" style="background-color: rgb(232, 240, 254);">
                    <div class="items" @click="clickCompany(company.inn || company.pinfl)" v-for="company in searchSuggestion.companies" :key="company" >
                        <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ company.companyName }}</p>
                    </div>
                </div>
            </div>
            <div class="card rounded p-0 mt-2" v-if="searchSuggestion.founders.length" style="background-color: rgb(232, 240, 254);">
                <div class="card-header d-flex text-dark text-start">
                    <img src="../assets/images/founders.png" class="me-2" width="26px" alt="companies">
                    <h5 class="m-0">
                        Учредители
                    </h5>
                </div>
                <div class="card-body px-3" style="background-color: rgb(232, 240, 254);">
                    <div class="items"  @click="submitSearch(founder.fullName)" v-for="founder in searchSuggestion.founders" :key="founder" >
                        <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ founder.fullName }}</p>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <!-- <div class="filter">
            <p :class="activeFilterElement == 'All' ? 'active': ''" @click="activedFilterElement('All')">Все</p>
            <p :class="activeFilterElement == 'Organizations' ? 'active': ''" @click="activedFilterElement('Organizations')">Организации</p>
            <p :class="activeFilterElement == 'Founders' ? 'active': ''" @click="activedFilterElement('Founders')">Учредители</p>
            <p :class="activeFilterElement == 'Managers' ? 'active': ''" @click="activedFilterElement('Managers')">Руководители</p>
            <p :class="activeFilterElement == 'Trademarks' ? 'active': ''" @click="activedFilterElement('Trademarks')">Товарные знаки</p>
        </div> -->
    </div>

</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    data() {
        return {
            activeFilterElement: 'All',
            searchText: '',
            showModal: false,
            debounceFunction: null,
            isProcess: false,
        }
    },
    methods: {
        ...mapGetters([
            'getSearchSuggestion',
            'getBreadcrumb'
        ]),
        ...mapMutations([
            'setSearchSuggestion',
            'setBreadcrumb'
        ]),
        ...mapActions([
            'searchSuggestion_api'
        ]),
        activedFilterElement(element) {
            this.activeFilterElement = element
            this.submitSearch();
        },
        clearSearch() {
            this.searchText = '';
            $('#InputSuggestion').focus();  
        },
        clickCompany(inn) {
            let breadcrumb = this.getBreadcrumb();
                let routeName = 'search';
                let searchBreadcrumb = {
                    id: routeName,
                    img: `${routeName}.png`,
                    name: 'Поиск',
                    url: `/search/All?text=${this.searchText}&pageSize=10&page=1`
                };
                if (!breadcrumb.find(element => element.id == routeName)) {
                    breadcrumb.splice(1,0,searchBreadcrumb);
                } else {
                    breadcrumb[breadcrumb.findIndex(object => object.id == routeName)] = searchBreadcrumb;
                }
                breadcrumb = breadcrumb.slice(0, breadcrumb.findIndex(object => object.id == routeName) + 1);
                this.setBreadcrumb(breadcrumb);
            if(inn.length == 9) {
                if(this.$route.name == 'company') {
                    window.location= `/search/company/${inn}`;
                }else {
                    this.$router.push({name:'company', params: {inn: inn}});
                }
            } else {
                if(this.$route.name == 'YATT') {
                    window.location= `/search/individual-entrepreneur/${inn}`;
                }else {
                    this.$router.push({name:'YATT', params: {inn: inn}});
                }
            }
            // window.location.reload();
        },
        submitSearch(text) {
            this.searchText = text;
            this.$router.push({ name: 'search',params: {type: 'All'}, query: { text: this.searchText, pageSize: 10, page:1 } });
        },
        async debounce() {
            clearTimeout(this.debounceFunction);
            this.isProcess = true;

            this.debounceFunction = setTimeout( async() => {
                if (this.searchText != '') {
                   await  this.searchSuggestion_api(this.searchText);
                   this.isProcess = false;
                }else {
                   this.isProcess = false;

                }
            },700);
        }
    },
    computed: {
        searchSuggestion() {
            return this.getSearchSuggestion();

        }
    },
    beforeMount() {
        this.searchText = this.$route.query.text;

    },
    async mounted() {
        let inputSuggestion = document.getElementById('InputSuggestion');
        let self = this;
        window.addEventListener('click', function () {
            if (this.document.activeElement.id != 'InputSuggestion') {
                self.showModal = false;
            }
        });
        if (this.$route.name != 'search' && this.$route.name != 'home'){
            let Breadcrumb = this.getBreadcrumb();
                for (let i = 0; i < Breadcrumb.length; i++) {
                const element = Breadcrumb[i];
                if (element.id == 'search') {
                    const params = new URLSearchParams(element.url?.split('?')[1]);
                    const text = params.get('text');
                    if (text) {
                        this.searchText = text;
                        this.isProcess = true;
                        await this.searchSuggestion_api(this.searchText);
                        this.isProcess = false;
                    }
                }
            }
        }
        inputSuggestion.addEventListener('focus', function () {
            self.showModal = true;
        } )
    }
}
</script>

<style>

#SearchForLandingPageComponent .input-group .form-control {
    background-color: #FFFFFF;
    border-radius: 15px !important;
    padding: 3px 3px 3px 20px;
    border: none;
    height: 55px;
    box-shadow: none !important;
    font-size: 18px;
}

#SearchForLandingPageComponent .container .card .input-group .form-control {
    background-color: #E9F2FF;
    border-radius: 9px;
    padding: 3px 3px 3px 15px;
    border: none;
    height: 40px;
    box-shadow: none !important;

}

/* #SearchForLandingPageComponent .input-group  .search-btn {
    margin-left: 0px !important;
    z-index: 5;
    border-radius: 10px;
    font-size: 13px;
    padding: 5px 12px ;
    background-color: #2290FE;
    color: #FFFFFF;
    border: none;
    right: 5px;
    top: 5px;
} */

#SearchForLandingPageComponent .input-group  .search-btn {
    margin-left: 4px !important;
    border-radius: 10px;
    font-size: 16px !important;
    padding: 10px 24px 10px 24px !important;
    background-color: #2290FE;
    color: #FFFFFF;
    border: none;
}
#SearchForLandingPageComponent .input-group .actions-for-suggestion {
    z-index: 5;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #FFFFFF !important;
}
#SearchForLandingPageComponent .Main-frame .input-group .input-group-append .filter-btn {
    margin-left: -215px;
    padding-right: 20px;
    color: #000;
}

#SearchForLandingPageComponent .container .card .filter {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.Main-frame .container .card .filter .active {
    color: #0D4CD6;
    background-color: #e8e8e8;
    border-radius: 8px;
}

.Main-frame .container .card .filter p {
    padding: 10px 15px;
    font-weight: 500;
    margin: 0;
}

.Main-frame .container .card .filter p:hover {
    cursor: pointer;
    color: #0B72BD;
}

#SearchForLandingPageComponent .container .card .input-group .btn {
    height: 50px;
    color: #656565;
}

</style>