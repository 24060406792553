<template>
    <div class="breadcrumb-container">
        <div class="container ">
            <div class="breadcrumb" :class="this.$route.name == 'search' ? ' white-theme': ''">

                <div v-for="breadcrumb in breadcrumbs" class="breadcrumb-item">
                    <RouterLink class="text-dark" :to="breadcrumb.url">
                        <!-- <img  :src='`/img/${breadcrumb.id}.png`' height="20px"
                            :alt="breadcrumb.id"> -->
                        <p  class="m-0 px-1 d-inline-block text-nowrap" >
                            {{ breadcrumb.name }}</p>
                    </RouterLink>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    methods: {
        ...mapGetters([
            'getBreadcrumb'
        ]),
        ...mapMutations([
            'setBreadcrumb'
        ])
    },
    computed: {
        breadcrumbs() {
            return this.getBreadcrumb()
        }
    },
    watch: {
        '$route.fullPath'(to, from) {
            if (to != '/') {
                let breadcrumb = this.getBreadcrumb();
                let routeName = this.$route.name;
                let searchBreadcrumb = {
                    id: routeName,
                    img: `${routeName}.png`,
                    name: this.$route.meta.nameRU,
                    url: this.$route.fullPath
                };
                if (!breadcrumb.find(element => element.id == routeName)) {
                    breadcrumb.push(searchBreadcrumb);
                } else {
                    breadcrumb[breadcrumb.findIndex(object => object.id == routeName)] = searchBreadcrumb;
                }
                breadcrumb = breadcrumb.slice(0, breadcrumb.findIndex(object => object.id == routeName) + 1);
                this.setBreadcrumb(breadcrumb);
            }
        }
    },
    mounted() {
        let breadcrumb = this.getBreadcrumb();
                let routeName = this.$route.name;
                let searchBreadcrumb = {
                    id: routeName,
                    img: `${routeName}.png`,
                    name: this.$route.meta.nameRU,
                    url: this.$route.fullPath
                };
                if (!breadcrumb.find(element => element.id == routeName)) {
                    breadcrumb.push(searchBreadcrumb);
                } else {
                    breadcrumb[breadcrumb.findIndex(object => object.id == routeName)] = searchBreadcrumb;
                }
                breadcrumb = breadcrumb.slice(0, breadcrumb.findIndex(object => object.id == routeName) + 1);
                this.setBreadcrumb(breadcrumb);
    }
}
</script>


<style>

.breadcrumb-container {
    height: 50px;
    /* background: linear-gradient(90deg, #0469A8 0%, #2291FF 100%) !important; */
    display: flex;
    align-items: center;
}
.breadcrumb {
    display: flex;
    margin-bottom: 0 !important;
    padding: 5px !important;
}
.white-theme .breadcrumb-item {
    color: rgb(214, 214, 214) !important;
}
.breadcrumb-item {
    color: rgb(17, 17, 17);
}
.white-theme .breadcrumb-item:hover {
    color: rgb(214, 214, 214);
}
.breadcrumb-item:hover {
    color: rgb(17, 17, 17);

}
.white-theme .text-dark{
    color: white !important;
}
.white-theme .breadcrumb-item+.breadcrumb-item::before {
    color: rgb(214, 214, 214) !important;
}
.breadcrumb-item+.breadcrumb-item::before {
    color: rgb(20, 20, 20) !important;
}
</style>