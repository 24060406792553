<template>
    <MainHeader></MainHeader>
    <section>
        <div class="Main-frame  " :class="(this.$route.name != 'search') ? 'p-0' : ' pt-5 pb-5'">
            <div class="container">
                <div class="wrapper" :style="(this.$route.name != 'search' && this.$route.name != 'home') ? 'width:100%;':''">
                    <slot name="Main-frame">

                    </slot>
                    <SearchForLandingPageComponent v-if="this.$route.name != 'search' && this.$route.name != 'home'" class="py-3"></SearchForLandingPageComponent>
                    <BreadcrumbComponent class="d-none d-lg-flex" v-if="this.$route.name == 'search'"></BreadcrumbComponent>
                </div>
            </div>
        </div>
        <BreadcrumbComponent class="d-none d-lg-flex" v-if="this.$route.name != 'home' && this.$route.name != 'search'"></BreadcrumbComponent>

    </section>
    <slot></slot>

    <MainFooter></MainFooter>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import SearchForLandingPageComponent from '@/components/SearchForLandingPageComponent.vue';
import BreadcrumbComponent from '@/components/Breadcrumb.vue'

export default {
    components: {
        MainHeader,
        MainFooter,
        BreadcrumbComponent,
        SearchForLandingPageComponent
    }
}
</script>
